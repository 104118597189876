<template>
  <div>
      <multipleSelect
            :fieldAttributes="{  associatedOption: this.bandOptions,
                                 name: 'Bands',
                                 ignoreEmptyAdd:true,
                                 style:'max-width:fit-content;',
                                 anyValue:'MM_MODEM_BAND_ANY'
                               }"
            :fieldAttrInput="{ id:'cellularBands' }"
            v-model="cellularBands"
            :templateContent="result"
            :isValid="isValid"
            :checkBox="false"
        >
      </multipleSelect>
    </div>
</template>
<script>

import { getfieldAttrInput } from "@/js/helper.js";
import multipleSelect from '@/commonComponents/multipleSelect.vue';
import options from "@/cfg/options.json";


export default {
  components: {
    multipleSelect
  },
  props: ["field", "fieldAttributes", "result", "valueLine", "isValid"],

  data() {
    return {
      options: options,
    };
  },
  computed: {
    bandOptions(){
      return this.options.cellularBands;
    },
    fieldAttrInput() {
      return getfieldAttrInput(this.fieldAttributes);
    },
    cellularBands: {
      get() {
        let cellularBandsVal = [];//default
        if (typeof this.valueLine.radio != "undefined" &&
            typeof this.valueLine.radio.bands != "undefined" &&
            this.valueLine.radio.bands.length!=0
        ) {
          cellularBandsVal = this.valueLine.radio.bands;
        }
        return cellularBandsVal;
      },
      set(newVal) {
        this.$set(this.valueLine.radio, "bands", newVal);
      },
    },
  },
};
</script>